<template>
  <section id="dashboard-ecommerce">
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="primary"
      opacity="0.85"
      blur="2"
      rounded="sm"
    >
      <div>
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col cols="12" md="3" class="mb-md-0 mb-2">
                <label>Bayi Adı</label>
                <vue-autosuggest
                  :suggestions="filteredOptions"
                  :limit="5"
                  :input-props="{
                    id: 'autosuggest__input',
                    class: 'form-control',
                    placeholder: 'Cari adını yada kodunu giriniz.',
                  }"
                  @selected="onSelected"
                  @input="onInputChange"
                >
                  <template slot-scope="{ suggestion }">
                    <div class="d-flex align-items-center">
                      <div class="detail ml-50">
                        <b-card-text class="mb-0">
                          {{ suggestion.item.name }}
                        </b-card-text>
                        <small class="text-muted">
                          {{ suggestion.item.code }}
                        </small>
                      </div>
                    </div>
                  </template>
                </vue-autosuggest>
              </b-col>
              <b-col cols="12" md="3" class="mb-md-0 mb-2">
                <label>Bayi Kodu</label>
                <b-form-input
                  id="readOnlyInput"
                  :value="createUserReq.dealerErpCode"
                  readonly
                />
              </b-col>
              <b-col cols="12" md="3" class="mb-md-0 mb-2">
                <label>Email</label>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="createUserReq.email"
                    name="email"
                    :state="errors.length > 0 ? false : null"
                    type="email"
                    placeholder="Email"
                  />
                </validation-provider>
              </b-col>
              <b-col cols="12" md="3" class="mb-md-0 mb-2">
                <div class="d-flex mt-2 justify-content-end">
                  <b-button
                    style="margin-top: 0x"
                    type="button"
                    variant="primary"
                    @click="saveDealer()"
                  >
                    <feather-icon icon="ArrowUpIcon" class="mr-50" />
                    <span class="align-middle">Kaydet</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card no-body class="mb-0">
          <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="dealers"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="Kayıt bulunamadı."
          >
            <template #cell(isActive)="data">
              <b-badge
                v-if="data.item.isActive"
                pill
                :variant="`light-success`"
                class="text-capitalize"
              >
                Aktif
              </b-badge>
              <b-badge
                v-else
                pill
                :variant="`light-warning`"
                class="text-capitalize"
              >
                Pasif
              </b-badge>
            </template>
            <template #cell(emailActivation)="data">
              <b-badge
                v-if="data.item.emailActivation"
                pill
                :variant="`light-success`"
                class="text-capitalize"
              >
                Onaylı
              </b-badge>
              <b-badge
                v-else
                pill
                :variant="`light-warning`"
                class="text-capitalize"
              >
                Beklemede
              </b-badge>
            </template>
            <template #cell(persons)="data">
              <div class="d-flex justify-content-center">
                <b-button
                  variant="primary"
                  class="btn-icon"
                  :to="{
                    name: 'dealers-persons',
                    params: { id: data.item.id },
                  }"
                >
                  <feather-icon icon="UsersIcon" />
                </b-button>
              </div>
            </template>
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'dealers-persons',
                    params: { id: data.item.id },
                  }"
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Kişiler</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showDiscountPanelModal(data.item)">
                  <feather-icon icon="ArrowDownIcon" />
                  <span class="align-middle ml-50">İskonto Belirle</span>
                </b-dropdown-item>
                <b-dropdown-item @click="changeStatus(data.item.id)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50" v-if="data.item.isActive"
                    >Pasif Yap</span
                  >
                  <span class="align-middle ml-50" v-else>Aktif Yap</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item @click="loginDealer(data.item)">
                  <feather-icon icon="ArrowUpIcon" />
                  <span class="align-middle ml-50">Sipariş Yükle</span>
                </b-dropdown-item> -->
                <b-dropdown-item @click="deleteDealer(data.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Sil</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
              </b-col>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <b-modal
        id="modal-cartImport"
        v-model="showDiscountPanel"
        centered
        title="Bayi İskonto Belirleme"
        :hide-footer="true"
      >
        <b-form-group label="İskonto oranı % " label-for="number">
          <cleave
            id="number"
            v-model="userDiscount"
            class="form-control"
            :raw="false"
            :options="number"
          />
        </b-form-group>

        <br />
        <div class="item-quantity d-flex justify-content-center">
          <div class="d-flex mt-2">
            <b-button
              style="margin-top: 0x"
              type="button"
              variant="primary"
              @click="discountSave"
            >
              <feather-icon icon="ArrowUpIcon" class="mr-50" />
              <span class="align-middle">Kaydet</span>
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-modal
        id="modal-cartImport"
        v-model="showUploadPanel"
        centered
        title="Excelden Sipariş Aktarma"
        :hide-footer="true"
      >
        <b-form-file
          v-model="file"
          placeholder="Bir dosya seçin veya buraya bırakın"
          drop-placeholder="Dosyayı buraya bırakın.."
          accept=".xlsx, .xls"
        />

        <br />
        <div class="item-quantity d-flex justify-content-center">
          <div class="d-flex mt-2">
            <b-button
              style="margin-top: 0x"
              type="button"
              variant="primary"
              @click="cartUpload"
            >
              <feather-icon icon="UploadIcon" class="mr-50" />
              <span class="align-middle">Yükle</span>
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-modal
        size="lg"
        centered
        v-model="showAddress"
        title="Adres Seçimi"
        :hide-footer="true"
      >
        <div v-for="(data, index) in address" :key="index">
          <b-row>
            <b-col
              cols="8"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <div class="d-flex align-items-center">
                <b-avatar class="mr-2" variant="light-success">
                  <feather-icon
                    size="18"
                    icon="UserIcon"
                    v-if="data.type === 'PROFIL'"
                  />
                  <feather-icon
                    size="18"
                    icon="HomeIcon"
                    v-if="data.type === 'ADRESKARTI'"
                  />
                </b-avatar>
                <div>
                  <div class="font-weight-bolder">
                    {{ data.title }}
                  </div>
                  <div class="font-small-2 text-muted">
                    {{ data.address }}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col
              cols="7"
              sm="4"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <div class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder">
                    {{ data.town }}
                  </div>
                  <div class="font-small-2 text-muted">
                    {{ data.district }}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col
              cols="4"
              sm="2"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <b-button
                style="margin-top: 0x"
                type="button"
                v-b-modal.modal-center
                variant="primary"
                @click="selectAddress(data)"
              >
                <feather-icon icon="CheckIcon" class="mr-50" />
                <span class="align-middle">Seç</span>
              </b-button>
            </b-col>
          </b-row>
          <br />
        </div>
      </b-modal>
    </b-overlay>
  </section>
</template>

<script>
import {
  BFormInput,
  BOverlay,
  BModal,
  BCard,
  BCardHeader,
  BCardBody,
  BFormFile,
  BRow,
  BCardText,
  BButton,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BImg,
  BFormSpinbutton,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required, email } from "@validations";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { VueAutosuggest } from "vue-autosuggest";
import Cleave from "vue-cleave-component";
export default {
  components: {
    BRow,
    BImg,
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BCard,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BModal,
    BOverlay,
    BFormInput,
    BCardHeader,
    BCardBody,
    BButton,
    BFormSpinbutton,
    vSelect,
    Cleave,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BCardText,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  data() {
    return {
      dealers: [],
      address: [],
      tableColumns: [
        { key: "dealerErpName", label: "Bayi Adı" },
        { key: "dealerErpCode", label: "Bayi Kodu" },
        { key: "email", label: "Email" },
        { key: "discountFormatted", label: "Iskonto" },
        { key: "emailActivation", label: "Onay Durumu" },
        { key: "isActive", label: "Durum" },
        { key: "actions", label: "İşlemler" },
      ],
      show: false,
      showDiscountPanel: false,
      showUploadPanel: false,
      userDiscount: 0,
      number: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
      addressTableColumns: [
        { key: "icon", label: "" },
        { key: "title", label: "Adres" },
        { key: "select", label: "Seç" },
      ],
      selectedDealerId: 0,
      selectedCartId:0,
      createUserReq: {
        dealerErpName: "",
        dealerErpCode: "",
        email: "",
      },
      datasuggest: [],
      filteredOptions: [],
      selected: null,
      file: null,
      selectedAddressId: 0,
      showAddress: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.show = true;
      this.$http
        .get("/User/GetDealers")
        .then((response) => {
          this.dealers = response.data.result;
          this.show = false;
        })
        .catch((error) => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
    onSelected(item) {
      console.log(item);
      this.selected = item;
      this.createUserReq.dealerErpCode = item.item.code;
      this.createUserReq.dealerErpName = item.item.name;
    },
    getAddress() {
      this.show = true;
      this.$http
        .get(`/User/GetDealerAddressByUser?userId=${this.selectedDealerId}`)
        .then((response) => {
          console.log(response.data);
          this.address = response.data.result;
          this.show = false;
          this.showAddress = true;
        })
        .catch((error) => {
          this.show = false;
          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
    selectAddress(data) {
      console.log(data);
      this.selectedAddressId = data.id;
      this.showAddress = false;
      this.show = true;
      this.$http
        .get(`/Cart/CreateOrderByUser?cardId=${this.selectedCartId}&addressId=${this.selectedAddressId}&userId=${this.selectedDealerId}`)
        .then(response => {
          this.init();
          this.$swal({
            title: response.data.message,
            text: `Evrak Numarası: ${response.data.result}`,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        })
        .catch(error => {
          this.show = false;
          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        });
    },
    cartUpload() {
      if (this.file === null) {
        this.$swal({
          title: "Uyarı!",
          text: "Lütfen önce dosya seçiniz.",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          confirmButtonText: "Kapat",
          buttonsStyling: false,
        });
        return;
      }

      this.show = true;
      this.showUploadPanel = false;
      let formData = new FormData();

      formData.append("file", this.file);
      this.$http
        .post(
          `Cart/ImportCartWithExcelByUser?userId=${this.selectedDealerId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.show = false;

          if (response.data.message.includes("bulunamadı")) {
            let notFoundList = response.data.result;
            let message = response.data.message;
            var i;
            for (i = 0; i < notFoundList.length; i++) {
              message += "<br/> Ürün Kodu: " + notFoundList[i];
            }
            message +=
              "<br/> <strong>Lütfen ürün kodlarını kontrol ettikten sonra dosyayı tekrar yükleyiniz.</strong>";
            this.$swal({
              title: "Uyarı!",
              html: message,
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              confirmButtonText: "Kapat",
              buttonsStyling: false,
            });
            return;
          }


          this.getAddress();
         
          this.file = null;
        })
        .catch((error) => {
          this.show = false;
          console.log(error);
          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
          this.file = null;
        });
    },
    onInputChange(text) {
      if (text === "" || text === undefined) {
        return;
      }

      if (text.lenght < 3) {
        return;
      }

      this.show = true;
      this.$http
        .get(`/User/GetERPDealers?dealerText=${text}`)
        .then((response) => {
          this.datasuggest = response.data.result;
          this.filteredOptions = [
            {
              data: this.datasuggest,
            },
          ];
          this.show = false;
        })
        .catch((error) => {
          this.show = false;
          console.log(error);
          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
    saveDealer() {
      this.show = true;
      this.$http
        .post(`/User/CreateDealer`, this.createUserReq)
        .then((response) => {
          this.createUserReq = {
            dealerErpName: "",
            dealerErpCode: "",
            email: "",
          };
          this.init();
          this.$swal({
            title: "Başarılı!",
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
    discountSave() {
      this.show = true;
      const req = {
        dealerId: this.selectedDealerId,
        discount: this.userDiscount,
      };

      this.$http
        .post(`/User/AddDealerDiscount`, req)
        .then((response) => {
          this.showDiscountPanel = false;
          this.init();
          this.$swal({
            title: "Başarılı!",
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
    showDiscountPanelModal(data) {
      this.showDiscountPanel = true;
      this.selectedDealerId = data.id;
      this.userDiscount = data.discount;
    },
    changeStatus(id) {
      this.show = true;
      this.$http
        .get(`/User/MakeUserPassive?userId=${id}`)
        .then((response) => {
          this.init();
          this.$swal({
            title: "Başarılı!",
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
    deleteDealer(id) {
      this.show = true;
      this.$http
        .get(`/User/DeleteUser?id=${id}`)
        .then((response) => {
          this.init();
          this.$swal({
            title: "Başarılı!",
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
    format(date) {
      var month = date.toLocaleString("tr-TR", { month: "short" });
      return date.getDate() + " " + month + " " + date.getFullYear();
    },
    loginDealer(data) {
      console.log(data);
      this.showUploadPanel = true;
      this.selectedDealerId = data.id;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
</style>
